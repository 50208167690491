<template>
  <div class="w-100">
    <div class="w-100" style="position: relative">
      <loader-spinner-small v-if="showingSpinner || showSpinnerFromProp" centered></loader-spinner-small>
      <div class="card-block p-0">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row flex-wrap justify-content-between">
            <div class="d-flex  flex-row align-items-top mr-2 mb-q input-wrapper-in-system-activate-upload-file" >
              <div class="form-group mb-0 config-for-upload-select-block">
                <label
                  id="config-for-upload-select-block-button"
                  class="btn btn-info mb-0 config-for-upload-select-block-button"
                  role="button"
                >
                  <i class="fa fa-upload mr-h"></i>
                  {{ $t('system.selectFile') }}
                  <input
                    class="form-control-file"
                    @change="fileSelectHandler"
                    type="file"
                    id="selectFiles"
                    name="files"
                    value="Import"
                    ref="selectFile"
                    style="display: none"
                  />
                </label>
                <label v-tooltip="{content: this.uploadedFromDiskFileNameForDisplayForTooltip, popperOptions: {
                              modifiers: {
                                preventOverflow: {
                                  boundariesElement: 'window'
                                }
                              }
                            }}" class="multiselect__placeholder btn mb-0" for="selectFiles">
                  {{ uploadedFromDiskFileNameForDisplay }}
                </label>
              </div>
            </div>
            <transition name="SubmitButtonTransitionInSystemActivateTextLicence" mode="out-in">
            <div clas="mb-q" >
              <button
                :class="{ 'btn-success': !uploadButtonDisabled, 'btn-default': uploadButtonDisabled }"
                :disabled="uploadButtonDisabled"
                type="button"
                class="btn btn-md"
                @click="uploadButtonHandler"
              >
                {{ $t('system.uploadFileButton') }}
              </button>
            </div>
            </transition>
          </div>

          <div>
            <div class="d-flex justify-content-start">
              <div>
                <a href="#" class="ml-q" @click.prevent="clearSelectedFile">
                  <span>{{ $t('general.clear') }}</span>
                </a>
              </div>
              <div class="ml-2 errors-in-json" v-if="areErrorsInFile">
                {{ $t('configExportImport.fileParsingError') }}
              </div>
            </div>
          </div>
<!--          <transition name="ConfigImportTransition" mode="out-in">-->
<!--            <pre-->
<!--              class="w-100"-->
<!--              style="max-height: 45vh; overflow-y: auto; overflow-x: hidden"-->
<!--              v-if="uploadedFromDiskForShow && viewFile"-->
<!--              >{{ uploadedFromDiskForShow }}</pre-->
<!--            >-->
<!--            >-->
<!--          </transition>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNotifications from 'vue-notifications';
import systemService from "../../services/systemService";

export default {
  name: 'SystemUploadLicenceFile',
  components: {},
  data() {
    return {
      uploadedFromDiskForShow: '',
      isSpinnerShowing: false,
      areErrorsInFile: false,
      uploadedFromDiskFileName: '',
      uploadedFromDiskFile: undefined,
      viewFile: false
    };
  },
  computed: {
    islicenceFileUploadingRequestExecuting(){
      return this.$store.state.licenceFileUploadingRequestExecuting
    },
    uploadedFromDiskFileNameForDisplayForTooltip(){
      if (this.uploadedFromDiskFileNameForDisplay === this.$t('system.selectedFileName')) {
        return '';
      } else {
        return this.uploadedFromDiskFileNameForDisplay
      }
    },
    uploadedFromDiskFileNameForDisplay() {
      try {
        if (this.uploadedFromDiskFileName !== '') {
          return this.uploadedFromDiskFileName;
        }
        return this.$t('system.selectedFileName');
      } catch (e) {
        return this.$t('system.selectedFileName');
      }
    },
    uploadButtonDisabled() {
      if (this.areErrorsInFile || this.uploadedFromDiskFile === '' || this.uploadedFromDiskFile === undefined || this.islicenceFileUploadingRequestExecuting) {
        return true;
      }
      return false;
    },
    showingSpinner() {
      if (this.isSpinnerShowing) {
        return true;
      }
      return false;
    }
  },
  props: {
    showSpinnerFromProp: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  methods: {
    uploadButtonHandler() {
      try {
        if (this.uploadedFromDiskFile === '' || this.uploadedFromDiskFile === undefined) {
          console.log('no data for send');
          return;
        }
        const formDataForSendInAPI = new FormData();
        formDataForSendInAPI.append('file', this.uploadedFromDiskFile);
        systemService.uploadLicenceFile(this, formDataForSendInAPI)
      } catch (e) {
        VueNotifications.error({ message: this.$t('configExportImport.fileParsingError') });
      }
    },
    successUploadHandler() {
      this.clearSelectedFile();
      this.$emit('success');
    },
    clearSelectedFile() {
      try {
        this.uploadedFromDiskForShow = '';
        this.$refs.selectFile.value = '';
        this.areErrorsInFile = false;
        this.uploadedFromDiskFileName = '';
        this.uploadedFromDiskFile = undefined;
        this.viewFile = false;
      } catch (e) {
        console.log(e);
      }
    },
    showSpinner() {
      this.isSpinnerShowing = true;
    },
    hideSpinner() {
      this.isSpinnerShowing = false;
    },
    fileSelectHandler() {
      this.setFromUploadedFileButtonHandler();
    },
    setFromUploadedFileButtonHandler() {
      // this.clearSelectedFile();
      // console.log('from uploaded file');
      const files = document.querySelector('#selectFiles');
      // console.log(files);
      if (files.length <= 0) {
        return false;
      }
      this.uploadedFromDiskFileName = files.files[0].name;
      this.uploadedFromDiskFile = files.files[0];

      const fr = new FileReader();
      this.showSpinner();

      fr.onload = (e) => {
        console.log(this.uploadedFromDiskFile);
        this.uploadedFromDiskForShow = e.target.result;
        this.hideSpinner();
      };

      fr.readAsText(files.files.item(0));
    },
    whenErrorActions() {
      this.areErrorsInFile = true;
      this.uploadedFromDiskForShow = '';
      // this.clearSelectedFile();
    }
  }
};
</script>

<style scoped>
    .input-wrapper-in-system-activate-upload-file {
    min-width: 250px !important;
    width: 73% !important;
  }
.errors-in-json {
  color: red;
}
/*.location-name-block {*/
/*  font-size: 1.1em;*/
/*}*/
.config-for-upload-select-block {
  width: 100%;
  border: 1px solid #e3e8ec;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #35495e;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: flex;
}
#config-for-upload-select-block-button {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
<style>
.SubmitButtonTransitionInSystemActivateTextLicence-enter-active,
.SubmitButtonTransitionInSystemActivateTextLicence-leave-active {
  transition: opacity 0.5s;
}

.SubmitButtonTransitionInSystemActivateTextLicence-enter,
.SubmitButtonTransitionInSystemActivateTextLicence-leave-to {
  opacity: 0;
}
</style>
